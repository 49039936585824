import { AppSignature } from 'src/app/models/app-signature';
import { InstituteModel } from '../../institute/models/institute-model';
import { AddressModel } from 'src/app/models/address.model';
import { UserRolesPermissionMap } from '../../notebook-user-roles/models/notebook-roles';
import { InstituteGroup } from '../../institute-group/models/institute-group-model';


export class StaffModel extends AppSignature {
  salutation: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  profilePicture: string;
  isActive: boolean;
  userNameType: number = 0;
  parentInstituteID: string;
  parentInstituteGroupID: string;
  reportingUserID: string;
  staffID: string;
  isTeachingStaff: boolean;
  businessModelType: number;
  applicationMode: number;
  qualification: string;
}

export class StaffDTO {
  staff: StaffModel = new StaffModel();
  loginRecord: any;
  role: any;
  institute: InstituteModel = new InstituteModel();
  instituteGroup: InstituteGroup = new InstituteGroup();
  address: AddressModel = new AddressModel();
  username: string = '';
  userRoleIDs: Array<UserRolesPermissionMap> = [];
}

export class user {
  username: string;
  password: string;
  isForcedPasswordResetReqiuired: boolean;
}

export class StaffPostModel extends StaffDTO {
  user: user = new user();
}

