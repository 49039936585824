import { AppSignature } from 'src/app/models/app-signature';
import { AddressModel } from 'src/app/models/address.model';
import { UserRolesPermissionMap } from '../../notebook-user-roles/models/notebook-roles';
import { FileTransferModel } from 'src/app/models/file-transfer';


export class AgentModel extends AppSignature {
  name: string;
  email: string;
  phone: string;
  title: string;
  isActive: boolean;
  userNameType: number = 0;
  agentID: string;
  parentAgentID: string;
  parentReportingManagerID: string;
  applicationMode: number;
  agentType: number;
  profileImage: string;
  useThirdPartyPayment: boolean;
  accountSecret: string;
  accountKey: string;
  paymentGatewayProvider: number;
  websiteUrl: string;
  brandName: string;
  logoUrl: string;
  altLogoUrl: string;
  iconUrl: string;
  altIconUrl: string;
  paymentGatewayUrl: string;
  useAgentBranding: boolean;
  allowSendingPaymentLink: boolean;
  pan: string;
  panFilePath: string;
  adhaar: string;
  adhaarFilePath: string;
  businessCategory: number;
  parentCompanyID: string;
  parentBankDetailsID: string;
  authorityProofPath: string;
  registrationCategory: number;
  isAuthorizedSignatory: boolean;
  designation: string;
}

export class AgentCommissionModel extends AppSignature {
  parentAgentID: string;
  commission: number;
  commissionType: number = 1;
  discount: number;
  discountType: number = 1;
  isActive: boolean;
  prorateType: number = 0;
  useAgentProrate: boolean;
}

export class agentCommissionDTO {
  agentCommission: AgentCommissionModel = new AgentCommissionModel();
}

export class Company extends AppSignature {
  name: string;
  email: string;
  phoneNumber: string;
  businessEntityType: number;
  parentAddressID: string;
  tan: string;
  tanFilePath: string;
  pan: string;
  panFilePath: string;
  gst: string;
  gstFilePath: string;
  parentBankDetailsID: string;
  registerUnderGST: boolean;
  gstExemption: string;
  isActive: boolean;
  landLineNumber: string;
  submittedFormPath: string;
  agreementFilePath: string;
  authorizedSignatoryID: string;
}

export class CompanyDTO {
  company: Company = new Company();
  authorizedSignatory: AgentModel = new AgentModel();
}

export class AgentDocumentsVerification extends AppSignature {
  gstin: boolean = false;
  pan: boolean = false;
  bankStatement: boolean = false;
  adhaar: boolean = false;
  drivingLicence: boolean = false;
  passport: boolean = false;
  bankAcNo: boolean = false;
  bankName: boolean = false;
  bankAddress: boolean = false;
  ifsc: boolean = false;
  cancelledChequeAttached: boolean = false;
  authorizedSignatoryEmail: boolean = false;
  partnerType: string;
  payment: string;
  parentCompanyID: string;
}

export class BankDetails extends AppSignature {
  accountName: string;
  accountNumber: string;
  bank: string;
  code: string;
  branch: string;
  ifsc: string;
  cancelledChequeFilePath: string;
  address: string;
}

export class AgentDTO {
  role: any;
  username: string = '';
  agent: AgentModel = new AgentModel();
  address: AddressModel = new AddressModel();
  bankDetails: BankDetails = new BankDetails();
  companyAddress: AddressModel = new AddressModel();
  agentCommission: AgentCommissionModel = new AgentCommissionModel();
  rolesPermissionMap: UserRolesPermissionMap = new UserRolesPermissionMap();
  companyDocumentsVerification: AgentDocumentsVerification = new AgentDocumentsVerification();
  staffList: Array<SubAgentPostModel> = new Array<SubAgentPostModel>();
  ownerList: Array<SubAgentPostModel> = new Array<SubAgentPostModel>();
}

export class AgentViewDTO extends AgentDTO {
  loginRecord: any;
  companyView: CompanyDTO = new CompanyDTO();
}

export class user {
  username: string;
  password: string;
  isForcedPasswordResetReqiuired: boolean;
}

export class SubAgentPostModel {
  agent: AgentModel = new AgentModel();
  uploads: Array<FileTransferModel> = new Array<FileTransferModel>();
}

export class AgentPostModel extends AgentDTO {
  user: user = new user();
  sendWeclomeMessage: boolean;
  activateAgent: boolean = false;
  saveStaff: boolean = false;
  company: Company = new Company();
  fileUploadList: Array<FileTransferModel> = new Array<FileTransferModel>();
}



export class AgentCredit extends AppSignature {
  amount: number;
  parentUserID: string;
  creditType: number;
  remarks: String;
  agentType: number;

}

export class AgentCreditClaim extends AppSignature {
  amount: number;
  currencyCode: string;
  parentUserID: string;
  claimMode: number;
  reference: string;
  status: number
  creditType: number
  remarks: string;
}
export class AgentCreditReportDto {
  agentCommissionCredits: Array<AgentCredit>;;
  totalCreditAmount: number;
  currencyCode: string;
  creditType: number;
  agent: AgentModel = new AgentModel();
  agentCommissionCreditClaim: AgentCreditClaim = new AgentCreditClaim();
}

export class AgentCreditClaimPostModel {
  agentCreditClaim: AgentCreditClaim = new AgentCreditClaim();
  agentCreditIdList: Array<string> = new Array<string>();
  notify: boolean = false;
}

export enum BookingByAgentType {
  NA = 0,
  CommissionDiscount = 1
}

export class AgentView {
  id: string;
  agentID: string;
  brandName: string;
  logoUrl: string;
  altLogoUrl: string;
  iconUrl: string;
  altIconUrl: string;
  useAgentBranding: boolean;
}

export class DocumentVerificationPost {
  documents: AgentDocumentsVerification;
  uploads: Array<FileTransferModel> = new Array<FileTransferModel>();
  sendWelcomeMail: boolean = false;
  activatePartner: boolean = false;
  companyID: string;
}